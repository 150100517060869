import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Features from "../components/features"
import FullCTA from "../components/fullCTA"
import { BodyText } from "../components/bodyText"
import OverlayText from "../components/overlayText"

import { Sort } from "../services/utils"
import { useAnchor } from "../effects/anchor.effect"

const Services = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  article.pageMetaData.url = article.url
  const ctas = Sort(article.ctas, article.ctasSort)
  useAnchor()

  return (
    <Layout meta={article.pageMetaData || { title: article.name }}>
      <Hero
        data={article.hero}
        className="fixed"
        options={{ gravity: "center" }}
      />
      <OverlayText>
        <BodyText article={article} />
        <Features features={article.features} startSide="left" />
        {ctas &&
          ctas.length > 0 &&
          ctas.map((cta, idx) => <FullCTA data={cta} key={`s-cta-${idx}`} />)}
      </OverlayText>
    </Layout>
  )
}

export default Services

export const query = graphql`
  query ServicesQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
    }
  }
`
